function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import './alertify.scss';
import _ from 'lodash';
import alertify from 'alertifyjs';
import { App } from 'Shared/resources/assets/app/js/App';
import { fire } from 'Shared/resources/assets/app/js/helpers/eventHelpers';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
/**
 * Notification framework used to send notifications.
 */ var Notification = {
    /**
     * Notification types
     */ INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    /**
     * Shows an alert dialog.
     *
     * @param {string}   message         The message to be shown
     * @param {function} successCallback The callback called when OK button is pressed
     */ alert: function alert(message) {
        var successCallback = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : function() {};
        this.getInstance().alert(message, successCallback);
    },
    /**
     * Shows a prompt dialog.
     *
     * @param {string}   message         The message to be shown
     * @param {function} successCallback The callback called when OK button is pressed
     * @param {function} failCallback    The callback called when Cancel button is pressed
     * @param {string}   defaultValue    The input default value
     */ prompt: function prompt(message) {
        var successCallback = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : function() {}, failCallback = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : function() {}, defaultValue = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : '';
        this.getInstance().prompt(message, defaultValue, successCallback, failCallback);
    },
    /**
     * Shows a confirmation dialog.
     *
     * @param {string}   message         The message to be shown
     * @param {function} successCallback The callback called when OK button is pressed
     * @param {function} failCallback    The callback called when Cancel button is pressed
     * @param { ok: string, cancel: string } labels The buttons labels object
     */ confirm: function confirm(message) {
        var successCallback = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : function() {}, failCallback = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : function() {}, labels = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : null;
        var _labels_ok, _labels_cancel;
        this.getInstance().confirm(message, successCallback, failCallback).set('labels', {
            ok: (_labels_ok = labels === null || labels === void 0 ? void 0 : labels.ok) !== null && _labels_ok !== void 0 ? _labels_ok : ts('OK'),
            cancel: (_labels_cancel = labels === null || labels === void 0 ? void 0 : labels.cancel) !== null && _labels_cancel !== void 0 ? _labels_cancel : ts('CANCEL')
        });
    },
    /**
     * Shows a notification of the given type.
     *
     * @param {string}      message         The message to be shown
     * @param {string}      type            The notification type
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */ notify: function notify(message, type) {
        var autoClose = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true, dismissCallback = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : function() {}, event = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : null;
        this.getInstance().notify(message, type, autoClose ? 6 : 600, dismissCallback);
        if (_.isPlainObject(event) && event.hasOwnProperty('identifier')) {
            fire(event.identifier, event.data || {});
        }
    },
    /**
     * Shows an info notification.
     *
     * @param {string}      message         The message to be shown
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */ info: function info(message) {
        var autoClose = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, dismissCallback = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : function() {}, event = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : null;
        this.notify(message, this.INFO, autoClose, dismissCallback, event);
    },
    /**
     * Shows an success notification.
     *
     * @param {string}      message         The message to be shown
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */ success: function success(message) {
        var autoClose = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, dismissCallback = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : function() {}, event = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : null;
        this.notify(message, this.SUCCESS, autoClose, dismissCallback, event);
    },
    /**
     * Shows an warning notification.
     *
     * @param {string}      message         The message to be shown
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */ warning: function warning(message) {
        var autoClose = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, dismissCallback = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : function() {}, event = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : null;
        this.notify(message, this.WARNING, autoClose, dismissCallback, event);
    },
    /**
     * Shows an error notification.
     *
     * @param {string}      message         The message to be shown
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */ error: function error(message) {
        var autoClose = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, dismissCallback = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : function() {}, event = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : null;
        this.notify(message, this.ERROR, autoClose, dismissCallback, event);
    },
    /**
     * Get the Alertify object
     *
     * @return object
     */ getInstance: function getInstance() {
        var alertifyInstance = _object_spread({}, alertify);
        alertifyInstance.defaults = _object_spread_props(_object_spread({}, alertifyInstance.defaults), {
            closable: false,
            maximizable: false,
            resizable: false
        });
        return alertifyInstance;
    }
};
App.Notification = Notification;
export { Notification };
