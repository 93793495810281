import $ from 'jquery';
import _ from 'lodash';
import { App } from 'Shared/resources/assets/app/js/App';
import { fire } from 'Shared/resources/assets/app/js/helpers/eventHelpers';
import { Popup } from 'Shared/resources/assets/app/js/ui/libs';
var FormGenerator = {
    /**
     * Form generator events
     */ EVENT_FORM_GROUP_ELEMENT_TOGGLED: 'event_form_group_element_toggled',
    showLoaderOnFormSubmit: function showLoaderOnFormSubmit(containerId) {
        var container = document.getElementById(containerId);
        var button = container.querySelector('button[type=submit]');
        button && button.addEventListener('click', function() {
            return button.querySelector('.fa-spinner').classList.remove('hidden');
        });
    },
    /**
     * Apply the form attributes conditions.
     *
     * @param {string}  attributesContainerId
     * @param {object}  attributesConditions
     * @param {boolean} isSearchForm
     */ applyFormAttributesConditions: function applyFormAttributesConditions(attributesContainerId, attributesConditions, isSearchForm) {
        var $attributesContainer = $('#' + attributesContainerId);
        _.each(attributesConditions, (function(condition, attributeId) {
            // If the attribute has no condition set or the condition attribute is not present in the form
            // then we simply do nothing and always display the conditioned attribute.
            if (condition.conditionId === null || !_.has(attributesConditions, condition.conditionId)) {
                return;
            }
            var isReadOnlyAttribute = false;
            var $attribute = $attributesContainer.find('[name^=_dyn_attr_' + attributeId + ']').first();
            if ($attribute.length === 0) {
                // Possibly the attribute is a read-only one => attempt to get it using its read only name
                $attribute = $attributesContainer.find('[data-read-only-name=_dyn_attr_' + attributeId + ']');
                isReadOnlyAttribute = true;
            }
            var $closestUiFormGroup = $attribute.closest('.ui-form-group');
            // If the condition action is to show the attribute then the attribute will be hidden from the beginning.
            if (!condition.conditionHide) {
                $closestUiFormGroup.addClass('hidden ui-hidden-by-condition');
                this.toggleFormGroupElementsDisableState($closestUiFormGroup, isSearchForm);
            }
            // Adding an change event to the condition element to show/hide its related attribute.
            // An change event is automatically triggered in order to show/hide the attributes in case of
            // editing forms.
            if (!isReadOnlyAttribute) {
                $attributesContainer.find('[name=_dyn_attr_' + condition.conditionId + ']').on('change', (function(e) {
                    this.toggleFormGroupElements($closestUiFormGroup, condition, $(e.target).val(), isSearchForm);
                    // Hide empty sections (where all fields are hidden - if any)
                    this.toggleEmptySections($attributesContainer);
                }).bind(this)).trigger('change');
            } else {
                var $conditionAttribute = $attributesContainer.find('[name=_dyn_attr_' + condition.conditionId + ']');
                if ($conditionAttribute.length) {
                    this.toggleFormGroupElements($closestUiFormGroup, condition, $conditionAttribute.val(), isSearchForm);
                }
            }
            // Handle the case when the condition is an read only element.
            var $readOnlyCondition = $attributesContainer.find('[data-read-only-name=_dyn_attr_' + condition.conditionId + ']');
            if ($readOnlyCondition.length) {
                this.toggleFormGroupElements($closestUiFormGroup, condition, $readOnlyCondition.attr('data-value'), isSearchForm);
            }
        }).bind(this));
    },
    /**
     * Toggle the form group elements depending on the given arguments.
     *
     * @param {jQuery}  $formGroup
     * @param {object}  condition
     * @param {int}     value
     * @param {boolean} isSearchForm
     */ toggleFormGroupElements: function toggleFormGroupElements($formGroup, condition, value, isSearchForm) {
        var conditionValueWasSelected = _.includes(condition.conditionAppliesOn, parseInt(value));
        var toggleState = conditionValueWasSelected === condition.conditionHide;
        $formGroup.toggleClass('hidden ui-hidden-by-condition', toggleState);
        this.toggleFormGroupElementsDisableState($formGroup, isSearchForm);
        fire(this.EVENT_FORM_GROUP_ELEMENT_TOGGLED);
        // If we are in a popup then we must assure we adjust its height accordingly.
        Popup.adjustHeight();
    },
    /**
     * Disable/Enable the form group elements if the form is a search form.
     *
     * Disabling them prevents sending the values to backend. We don't want to do the same for regular forms
     * as the services may expect full information about entities and we really don't care if empty values are set
     * into db.
     *
     * @param {object}  $formGroup
     * @param {boolean} isSearchForm
     */ toggleFormGroupElementsDisableState: function toggleFormGroupElementsDisableState($formGroup, isSearchForm) {
        $formGroup.find('input, select, textarea').prop('disabled', isSearchForm && $formGroup.hasClass('ui-hidden-by-condition'));
    },
    /**
     * Toggle the sections where none fields are displayed.
     *
     * @param {jQuery} $container
     */ toggleEmptySections: function toggleEmptySections($container) {
        $('.ui-form-generator-section', $container).each(function() {
            var $section = $(this);
            var $sectionFormGroups = $section.find('.ui-form-group');
            var $sectionFormGroupsHidden = $sectionFormGroups.filter('.ui-hidden-by-condition');
            var hideSection = $sectionFormGroups.length > 0 && $sectionFormGroups.length === $sectionFormGroupsHidden.length;
            $section.toggleClass('hidden', hideSection);
        });
    }
};
App.FormGenerator = FormGenerator;
export { FormGenerator };
