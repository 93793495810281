function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { App } from 'Shared/resources/assets/app/js/App';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { error, success } from 'Shared/resources/assets/app/js/helpers/notificationHelpers';
/**
 * Postmark rebound checker which checks emails validity.
 */ var PostmarkReboundChecker = {
    /**
     * Check the given email.
     *
     * @param {string}          email   The email address to be checked
     * @param {string}          salt    Salt must be given as we don't have an md5 function in js
     * @param {object}          options Custom options to be given to the widget
     * @param {Event|undefined} event   The HTML event
     */ check: function check(email, salt) {
        var options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, event = arguments.length > 3 ? arguments[3] : void 0;
        this.email = email;
        this.salt = salt;
        if (event) {
            this.icon = event.target.querySelector('i');
            this.icon.classList.remove('fa-flag-checkered');
            this.icon.classList.add('fa-spinner', 'fa-spin');
        }
        this.setOptions(options);
        this.clearPreviousChecks();
        this.handlePostmarkCheck();
    },
    /**
     * Set the widget options.
     *
     * @param {object} options
     */ setOptions: function setOptions(options) {
        var _this = this;
        var defaultOptions = {
            showNotificationForValidEmails: true,
            autoCloseNotifications: false,
            onCheckComplete: function(response) {
                var _this_icon, _this_icon1;
                (_this_icon = _this.icon) === null || _this_icon === void 0 ? void 0 : _this_icon.classList.remove('fa-spinner', 'fa-spin');
                (_this_icon1 = _this.icon) === null || _this_icon1 === void 0 ? void 0 : _this_icon1.classList.add('fa-flag-checkered');
                if (response.inactive === true) {
                    var message = ts('The email address "%1" has been blocked due to hard-bounces or spam complaints.', [
                        _this.email
                    ]);
                    return error(message, _this.options.autoCloseNotifications);
                }
                if (_this.options.showNotificationForValidEmails) {
                    var message1 = ts('The email address "%1" is valid and email delivery seems working.', [
                        _this.email
                    ]);
                    return success(message1, _this.options.autoCloseNotifications);
                }
            }
        };
        this.options = _object_spread({}, defaultOptions, options);
    },
    /**
     * Clear the previous checks.
     */ clearPreviousChecks: function clearPreviousChecks() {
        if (window.Rebound) {
            document.getElementById('postmark-rebound-checker-script').remove();
            delete window.Rebound;
        }
    },
    /**
     * Handle the postmark check.
     */ handlePostmarkCheck: function handlePostmarkCheck() {
        window.ReboundSettings = {
            publicToken: '3z7sbqgjefha2rbfdvgejh',
            email: this.email,
            salt: this.salt,
            appearance: 'custom',
            complete: this.options.onCheckComplete
        };
        var script = document.createElement('script');
        script.id = 'postmark-rebound-checker-script';
        script.src = 'https://rebound.postmarkapp.com/widget/1.0';
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);
    }
};
App.PostmarkReboundChecker = PostmarkReboundChecker;
export { PostmarkReboundChecker };
