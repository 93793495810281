var uniqueIdCalls = 0;
export var uniqueId = function uniqueId() {
    var prefix = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
    uniqueIdCalls++;
    return prefix + (new Date().getTime() + uniqueIdCalls).toString(16) + Math.random().toString(16).slice(2);
};
export function waitFor(obj, propertyName) {
    var attempts = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0;
    return new Promise(function(resolve, reject) {
        var maxAttempts = 10;
        var delay = 700;
        if (obj[propertyName]) {
            resolve(obj[propertyName]);
        } else if (attempts < maxAttempts) {
            setTimeout(function() {
                resolve(waitFor(obj, propertyName, attempts + 1));
            }, delay);
        } else {
            reject(new Error("Max attempts reached. ".concat(propertyName, " is still null.")));
        }
    });
}
export var isEmpty = function(obj) {
    return [
        Object,
        Array
    ].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
};
